/*
 * @Author: pgee 包售后
 * @Date: 2021-07-01 12:58:30
 * @Description: file content
 */
import { FunctionalComponent, h } from "preact";
// import { Link } from "preact-router/match";
import { getCurrentUrl } from "preact-router";
import { useEffect, useRef, useState } from "preact/compat";
import * as style from "./style.less";

const FIX_SCROLL_TOP = 120;

const Header: FunctionalComponent = () => {
    const [displayMenu, setDisplayMenu] = useState(false);
    const [activeSubMenuKey, setActiveSubMenuKey] = useState('')
    // const [displaySubMenu, setDisplaySubMenu] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    const [currentUrl] = useState(getCurrentUrl());

    const productSubMenuRef = useRef<HTMLDivElement>(null);
    const serviceSubMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    useEffect(() => {
        const onLoad = () => {
            // 移除样式的行内样式
            productSubMenuRef.current.removeAttribute("style");
            serviceSubMenuRef.current.removeAttribute("style");
        };
        window.addEventListener("load", onLoad);

        return () => window.removeEventListener("load", onLoad);
    });

    /**
     * @description: 切换二级菜单
     * @param {string} activeKey 激活 menu key
     * @return {void}
     */
    const handleToggleSubMenu = (activeKey: string) => {
        if (activeKey === activeSubMenuKey) {
            setActiveSubMenuKey('')
            return
        }

        setActiveSubMenuKey(activeKey)
    }

    const handleLinkClick = (newDisplayMenu: boolean) => {
        setDisplayMenu(newDisplayMenu);
    }

    return (
        <div class="header-comp">
            <div
                class={`${style.modal} ${displayMenu ? style.active : ""}`}
                onClick={() => setDisplayMenu(!displayMenu)}
            ></div>
            <header
                class={`${style.header} ${scrollTop > FIX_SCROLL_TOP ? style.fixed : ""
                    }`}
            >
                <div
                    class={`${style.menuBox} ${displayMenu ? style.changed : ""
                        } ${style.mobile}`}
                    onClick={() => setDisplayMenu(!displayMenu)}
                >
                    <div class={style.menuIcon}>
                        <span class={style.line}></span>
                        <span class={style.line}></span>
                        <span class={style.line}></span>
                    </div>
                </div>
                <div class={style.left}>
                    <a
                        class={style.logo}
                        href={process.env.PAGE_HOME}
                        onClick={() => handleLinkClick(false)}
                        title="一起剪"
                    >
                        <h1>一起剪</h1>
                    </a>
                    <nav
                        class={`${style.menu} ${displayMenu ? style.changed : ""
                            }`}
                    >
                        <a
                            class={`${(currentUrl.includes("vip") || currentUrl.includes("invite-plan"))
                                    ? style.white
                                    : ""
                                }`}
                            href="https://creative.yiqijian.com/"
                            title="一起剪创意素材"
                        >
                            一起剪创意素材
                            <span class={style.iconHot}></span>
                        </a>
                        <a
                            class={`${style.pc} ${(currentUrl.includes("vip") || currentUrl.includes("invite-plan"))
                                    ? style.white
                                    : ""
                                } ${activeSubMenuKey === "product"
                                    ? style.active
                                    : ""
                                }`}
                            href="javascript:;"
                            onClick={() => handleToggleSubMenu("product")}
                        >
                            产品
                            <svg
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="2527"
                                width="48"
                                height="48"
                            >
                                <path
                                    d="M512.133 624.981l-292.16-292.155c-12.27-12.28-32.186-12.28-44.457 0-12.28099999 12.27-12.281 32.186 0 44.457l314.388 314.388c12.27 12.28 32.186 12.28 44.457 0l314.387-314.388c6.14-6.136 9.211-14.183 9.211-22.228s-3.071-16.092-9.21099999-22.228c-12.27-12.28-32.186-12.28-44.45700001-1e-8l-292.159 292.15500001z"
                                    p-id="2528"
                                    fill="#333333"
                                ></path>
                            </svg>
                            <div
                                class={`${style.subMenu}`}
                                ref={productSubMenuRef}
                                style={{ visibility: "hidden", width: 0 }}
                            >
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("hot-news")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_HOT_NEWS}
                                    title="行业资讯汇聚"
                                >
                                    行业资讯汇聚
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("material")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_MATERIAL}
                                    title="海量剪辑素材"
                                >
                                    海量剪辑素材
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("video-collector")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_VIDEO_COLLECTOR}
                                    title="网络视频提取"
                                >
                                    网络视频提取
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("hot-videos")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_HOT_VIDEOS}
                                    title="实时视频热榜"
                                >
                                    实时视频热榜
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("video-templates")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_VIDEO_TEMPLATES}
                                    title="视频模板定制"
                                >
                                    视频模板定制
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("smart-editor")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_SMART_EDITOR}
                                    title="智能在线剪辑"
                                >
                                    智能在线剪辑
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("auto-sounds")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_AUTO_SOUNDS}
                                    title="字幕语音播报"
                                >
                                    字幕语音播报
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("cloud-maker")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_CLOUD_MAKER}
                                    title="视频云端合成"
                                >
                                    视频云端合成
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("one-step")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_ONE_STEP}
                                    title="视频一键发布"
                                >
                                    视频一键发布
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("team-manager")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_TEAM_MANAGER}
                                    title="团队管理"
                                >
                                    团队管理
                                </a>
                            </div>
                        </a>
                        <a
                            class={`${style.pc} ${(currentUrl.includes("vip") || currentUrl.includes("invite-plan"))
                                    ? style.white
                                    : ""
                                } ${activeSubMenuKey === "service"
                                    ? style.active
                                    : ""
                                }`}
                            href="javascript:;"
                            onClick={() => handleToggleSubMenu("service")}
                        >
                            合作
                            <svg
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="2527"
                                width="48"
                                height="48"
                            >
                                <path
                                    d="M512.133 624.981l-292.16-292.155c-12.27-12.28-32.186-12.28-44.457 0-12.28099999 12.27-12.281 32.186 0 44.457l314.388 314.388c12.27 12.28 32.186 12.28 44.457 0l314.387-314.388c6.14-6.136 9.211-14.183 9.211-22.228s-3.071-16.092-9.21099999-22.228c-12.27-12.28-32.186-12.28-44.45700001-1e-8l-292.159 292.15500001z"
                                    p-id="2528"
                                    fill="#333333"
                                ></path>
                            </svg>
                            <div
                                class={`${style.subMenu} ${style.service}`}
                                ref={serviceSubMenuRef}
                                style={{ visibility: "hidden", width: 0 }}
                            >
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("deploy")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_DEPLOY}
                                    title="私有部署"
                                >
                                    私有部署
                                </a>
                                <a
                                    class={`${style.subMenuItem} ${currentUrl.includes("open-access")
                                            ? style.active
                                            : ""
                                        }`}
                                    href={process.env.PAGE_OPEN_ACCESS}
                                    title="开放接入"
                                >
                                    开放接入
                                </a>
                                <a
                                    class={`${style.subMenuItem}`}
                                    href="//rongmeiti.myzaker.com/"
                                    title="融媒体解决方案"
                                >
                                    融媒体解决方案
                                </a>
                            </div>
                        </a>
                        <a
                            class={`${(currentUrl.includes("vip") || currentUrl.includes("invite-plan"))
                                    ? style.white
                                    : ""
                                }`}
                            href={process.env.PAGE_VIP}
                            title="会员"
                        >
                            会员
                        </a>
                        <a
                            href='https://www.z-metaspace.com/'
                            title="宙世代元宇宙"
                            target="_blank" 
                        >
                            宙世代元宇宙
                        </a>
                        {/* <a
                        class={`${
                            currentUrl.includes("open-access")
                                ? style.active
                                : ""
                        }`}
                        href={process.env.PAGE_OPEN_ACCESS}
                        onClick={() => handleLinkClick(false)}
                        title="开放接入"
                    >
                        开放接入
                    </a>
                    <a
                        href="//rongmeiti.myzaker.com/"
                        onClick={() => setDisplayMenu(false)}
                        title="融媒体解决方案"
                    >
                        融媒体解决方案
                    </a> */}
                    </nav>
                </div>
                <section class={style.right}>
                    <a
                        href="//saas.zaker.cn/?from=yiqijian"
                        title="一起剪"
                        class={`${style.resetBtn} ${style.login} ${style.pc}`}
                    >
                        登录/注册
                    </a>
                    {/* <a
                        href="//saas-base.zaker.cn/auth/register?referer=https%3A%2F%2Fsaas.zaker.cn%2F%3Ffrom%3Dyiqijian"
                        title="一起剪"
                        class={`${style.resetBtn} ${style.register} ${style.pc} ${(currentUrl.includes("vip") || currentUrl.includes("invite-plan")) && scrollTop < FIX_SCROLL_TOP
                        ? style.white
                        : ""
                        }`}
                    >
                        注册
                    </a> */}
                </section>
            </header>
        </div>
    );
};

export default Header;
